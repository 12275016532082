<template>
  <div class="pd10">
    <el-card class="custom-card">
      <div slot="header" class="clearfix">
        <span class="border-l relative pdL10">数据导出</span>
        <el-button class="right" type="text" @click="exportClick">导出</el-button>
      </div>
      <ul class="export-data">
        <el-row>
          <el-col :span="8">
            <li class="layui-col-xs4">
              <a href="javascript:;">
                <i class="testing_web_iconfont icon-canshu"></i>
                <cite>仓房参数</cite>
              </a>
            </li>
          </el-col>
          <el-col :span="8">
            <li class="layui-col-xs4">
              <a href="javascript:;">
                <i class="testing_web_iconfont icon-luyou1"></i>
                <cite>无线路由</cite>
              </a>
            </li>
          </el-col>
          <el-col :span="8">
            <li class="layui-col-xs4">
              <a href="javascript:;">
                <i class="testing_web_iconfont icon-dangan"></i>
                <cite>仓房档案</cite>
              </a>
            </li>
          </el-col>
        </el-row>
      </ul>
    </el-card>
    <el-card class="custom-card mgT10">
      <div slot="header" class="clearfix">
        <span class="border-l relative pdL10">仓型设置</span>
        <el-button class="right" type="text" @click="onSubmit('form')">修改</el-button>
      </div>
      <el-form ref="form" :rules="rules" :model="form" label-width="80px">
        <el-form-item label="仓型名称" prop="cangType" class="custom-form-item">
          <el-select v-model="form.cangType" placeholder="请选择" style="width: 100%;">
            <el-option v-for="(item,index) in cangTypeList" :key="index" :value="item.cangType" :label="item.cangName">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称修改" prop="cangName" class="custom-form-item">
          <el-input v-model="form.cangName" placeholder="名称修改"></el-input>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        cangTypeList: [],
        form: {
          cangType: "",
          cangName: ''
        },
        rules: {
          cangType: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          cangName: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
        }
      }
    },
    mounted() {
      this.cfCangTypeList();
    },
    methods: {
      //导出
      exportClick() {
        this.$confirm('确认要导出 仓房参数、无线路由、仓房档案 的数据吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.Post(this.$api.archivesExport, {}).then((res) => {
            window.open(this.$api.BaseUrl + "file/down_export_data/" + res.data);
          }).catch((err) => {
            if (err.errMsg) {
              this.$message({
                type: 'error',
                message: err.errMsg
              });
            }
          })
        }).catch(() => {});
      },
      //仓型获取
      cfCangTypeList() {
        this.$axios.Get(this.$api.cfCangTypeList, {}).then((res) => {
          this.cangTypeList = res.data
        })
      },
      //修改提交
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$axios.Post(this.$api.cfCangTypeModify, this.form).then(() => {
              this.$message({
                type: 'success',
                message: "修改成功"
              });
              this.cfCangTypeList();
            }).catch((err) => {
              if (err.errMsg) {
                this.$message({
                  type: 'error',
                  message: err.errMsg
                });
              }
            })
          } else {
            return false;
          }
        });
      },
    }
  }
</script>

<style scoped lang="less">
  .export-data {
    margin: 0;
    padding: 0;

    .el-col+.el-col li {
      margin-left: 10px;
    }

    li {
      text-align: center;

      a {
        color: #333;
        text-decoration: none;
      }

      i {
        display: inline-block;
        width: 100%;
        height: 100px;
        line-height: 100px;
        text-align: center;
        border-radius: 2px;
        font-size: 30px;
        background-color: #F8F8F8;
        color: #333;
        transition: all .3s;
        -webkit-transition: all .3s;
      }

      cite {
        position: relative;
        top: 2px;
        display: block;
        color: #666;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 12px;
        font-style: normal;
        margin-top: 8px;
      }
    }
  }
</style>
